import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';

import {
    useProceduresUIStore, useProcedureUIStore,
    useUserPermissionsUIStore
} from '@core/useStores';
import { DATE_MMM_DD_YYYY_HH_MM } from '@shared/constants';
import { Table } from '@shared/components/Table';
import { SearchForm } from '@shared/components/SearchForm';
import { NoResultsView } from '@shared/components/NoResultsView';
import { ExpandableList } from '@shared/components/ExpandableList';
import ArrowRightIcon from '@assets/arrow-right.svg';
import EditIcon from '@assets/edit.svg';

import { ConfirmationModal } from '@shared/components/ConfirmationModal';
import { ToastMessage } from '@shared/components/Toast';

import { IPageQueryStoreSnapshotIn, IProcedureDevice } from 'Procedures/stores';
import { ProceduresFiltersModal } from '../ProceduresFiltersModal';
import { ProceduresFiltersBar } from '../ProceduresFiltersBar';
import { NoProceduresInGroup } from '../NoProceduresInGroup';
import { ProceduresSummary } from '../ProceduresSummary';
import { ProcedureModal } from '../ProcedureModal';
import { ProceduresExport } from '../ProceduresExport';

import { useStyles } from './ProceduresPage.styles';
import { messages } from 'Procedures/procedures.messages';
import { ThemeProps } from '@styles/theme';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { IListProcedureStore } from 'Procedures/stores/ListProcedure.store';
import { DeviceIcon } from '@shared/components/DeviceIcon';
import { Button } from '@shared/components/Button';
import { Typography } from '@mui/material';
import { IStoresEnv } from '@core/storesEnv';
import { getEnv } from 'mobx-state-tree';

type SubComponentProps = {
    row: {
        original: {
            subRows: Array<{
                description: string,
                access: {
                    id: string,
                    name: string,
                }[],
                devices: {
                    id: string,
                    name: string,
                }[],
                history: {
                    date: string,
                    description: string,
                }[],
            }>,
        }
    }
}

type FetchDataType = {
    pageIndex: number;
    sortBy: {
        id: string;
        desc: boolean;
    }[]
}

type ProcedureMedia = {
    src: string;
    description: string;
}

const EXPANDER_CELL_WIDTH = 24;

const NoInfo = () => {
    const styles = useGridStyles();

    return <div className={styles.noInfo}>{messages['proceduresPage.noInfo']}</div>
}

const columnHelper = createColumnHelper<IListProcedureStore>();

const columnsData = [
    columnHelper.display({
        id: 'expander',
        minSize: 40,
        maxSize: 40,
        header: () => (
            <div style={{ minWidth: EXPANDER_CELL_WIDTH }} />
        ),
        cell: ({ cell: { row } }) => {
            const styles = useGridStyles();
            const expanderHandler = row.getToggleExpandedHandler();

            return (
                <div
                    style={{
                        minWidth: EXPANDER_CELL_WIDTH,
                        display: 'flex',
                    }}
                    onClick={expanderHandler}
                >
                    {row.getCanExpand() && (
                        <img
                            src={ArrowRightIcon}
                            className={classNames(styles.expandIcon, { [styles.expanded]: row.getIsExpanded() })}
                        />
                    )}
                </div>
            )
        },
        size: EXPANDER_CELL_WIDTH + 16,
        enableSorting: false,
    }),
    columnHelper.accessor('name', {
        header: messages['proceduresPage.table.header.name'],
        size: 12,
        // maxSize: 300,
        minSize: 150,
        maxSize: 450,
        cell: ({ cell: { row }, getValue }) => {
            const styles = useGridStyles();
            const { procedurePreview } = row.original as {
                procedurePreview: ProcedureMedia
            };
            const media = procedurePreview;

            return (
                <div className={styles.nameWrapper}>
                    {
                        media
                            ? (
                                <img
                                    className={styles.procedureImage}
                                    src={media.src}
                                    alt={media.description}
                                />
                            )
                            : (
                                <div className={styles.procedureImage}>
                                    <DescriptionIcon color='primary'/>
                                </div>
                            )
                    }

                    <span className={styles.procedureName}>{getValue()}</span>
                </div>
            );
        },
    }),
    columnHelper.accessor('devices', {
        header: messages['proceduresPage.table.header.devices'],
        size: 15,
        minSize: 120,
        maxSize: 150,
        enableSorting: false,
        cell: ({ getValue }) => {
            const value = getValue();
            const values = _.uniqBy(value, (device: IProcedureDevice) => device.type);

            if (!values.length) {
                return <NoInfo />;
            }

            return (
                <div>
                    {
                        values.map((device: IProcedureDevice, index: number) =>
                            <DeviceIcon
                                platform={device.platform ?? ''}
                                type={device.type ?? ''}
                                id={index}
                            />
                        )
                    }
                </div>
            );
        },
    }),
    columnHelper.accessor('availableModes', {
        header: messages['proceduresPage.table.header.availableModes'],
        size: 19,
        minSize: 150,
        maxSize: 180,
        cell: ({ getValue }) => {
            const styles = useGridStyles();
            const value = getValue();
            if (!value.length) {
                return <NoInfo />;
            }

            return (
                <span className={styles.modesContainer}>
                    {value.map((mode) => (
                        <span title={mode}><Typography className={styles.mode}>{mode[0]}</Typography></span>
                    ))}
                </span>
            );
        },
    }),
    columnHelper.accessor('type', {
        header: messages['proceduresPage.table.header.type'],
        size: 6,
        minSize: 50,
        maxSize: 100,
        cell: ({ getValue }) => getValue() || <NoInfo />,
    }),
    // {
    //   header: messages['proceduresPage.table.header.collaboration'],
    //   accessor: 'collaboration',
    //   size: 14,
    //   maxSize: 150,
    //   cell: ({ getValue }) => {
    //     return value ? messages['proceduresPage.available'] : messages['proceduresPage.notAvailable'];
    //   },
    // },
    columnHelper.accessor('executionsCount', {
        header: messages['proceduresPage.table.header.executionsCount'],
        size: 6,
        minSize: 50,
        maxSize: 100,
        cell: ({ getValue }) => {
            const styles = useGridStyles();

            return <div className={styles.counter}>{getValue()}</div>;
        },
    }),
    columnHelper.display({
        id: 'edit',
        header: () => {

            return (
                <></>
            )
        },
        minSize: 40,
        maxSize: 40,
        enableSorting: false,
        cell: ({ cell: { row } }) => {
            const styles = useGridStyles();

            const procedureUIStore = useProcedureUIStore();
            const userPermissionsUIStore = useUserPermissionsUIStore();
            const original: Record<string, any> = row.original;

            const onCellClick = () => {
                procedureUIStore.setEditProcedureId(original.id);
                procedureUIStore.toggleProcedureModalOpen(true);
            }
            const defaultProcedure = original.companyId == "00000000-0000-0000-0000-000000000000" && !userPermissionsUIStore.isCompaniesAvailable;

            return (
                <button
                    onClick={() => (userPermissionsUIStore.canUpdateProcedure || defaultProcedure) && onCellClick()}
                    disabled={defaultProcedure || !userPermissionsUIStore.canUpdateProcedure}
                    className={styles.button}
                    title={messages['proceduresPage.editProcedure']}
                >
                    <img src={EditIcon} />
                </button>
            )

        },
    }),
]

const useGridStyles = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });
    return styles;
}

export const ProceduresPage: React.FC = () => {
    const userPermissionsUIStore = useUserPermissionsUIStore();
    const proceduresGridUIStore = useProceduresUIStore();
    const procedureUIStore = useProcedureUIStore();

    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const { filtersOptions } = getEnv<IStoresEnv>(proceduresGridUIStore);
    const [filtersModalOpen, toggleFiltersModalOpen] = useState(false);

    const initialSortState = {
        sorting: [{
            id: 'name',
            desc: false,
        }],
    };

    const columns: ColumnDef<IListProcedureStore, any>[] = React.useMemo(() => columnsData, []);

    useEffect(() => {
        proceduresGridUIStore.cleanUp();
        proceduresGridUIStore.togglePageActivity(true);

        proceduresGridUIStore.load();
        proceduresGridUIStore.loadStatistics();
        filtersOptions.loadFilterOptions();
        setTimeout(() => { proceduresGridUIStore.togglePageActivity(true) }, 100);

        return () => {
            proceduresGridUIStore.togglePageActivity(false);
            proceduresGridUIStore.cleanUp();
        }
    }, []);

    const onFetchData = ({ pageIndex, sortBy }: FetchDataType) => {
        const params: IPageQueryStoreSnapshotIn = { page: pageIndex };

        if (sortBy && sortBy.length) {
            console.log('Sorting data:', sortBy);
            params.sortFieldName = sortBy[0].id;
            params.sortDirection = Number(sortBy[0].desc);
        }
        if (proceduresGridUIStore.isActivePage) { proceduresGridUIStore.setParams(params); }
    };

    const onSearchTextChanged = (searchText: string) => {
        if (searchText && searchText.length === 1) {
            return;
        }

        proceduresGridUIStore.setParams({
            page: 0,
            searchText,
        });
    };

    const onFiltersModalOpen = () => toggleFiltersModalOpen(true);
    const onFiltersModalClose = () => toggleFiltersModalOpen(false);

    const renderRowSubComponent = React.useCallback(({ row }: SubComponentProps) => {
        const data = row.original.subRows[0];

        return (
            <div className={styles.body}>
                <div className={styles.descriptionWrapper}>
                    <h5 className={styles.header}>{messages['proceduresPage.subRow.title']}</h5>
                    <div className={styles.text}>
                        {data.description || <NoInfo />}
                    </div>
                </div>

                <div className={styles.devicesWrapper}>
                    <h5 className={styles.header}>{messages['proceduresPage.subRow.devices']}</h5>
                    <div className={styles.text}>
                        {
                            data.devices.length
                                ? <ExpandableList list={data.devices} valueName='platform' />
                                : <NoInfo />
                        }
                    </div>
                </div>

                <div className={styles.accessWrapper}>
                    <h5 className={styles.header}>{messages['proceduresPage.subRow.access']}</h5>
                    <div className={styles.text}>
                        {
                            data.access.length
                                ? <ExpandableList list={data.access} />
                                : <NoInfo />
                        }
                    </div>
                </div>

                <div className={styles.historyWrapper}>
                    <h5 className={styles.header}>{messages['proceduresPage.subRow.history']}</h5>
                    <div className={styles.text}>
                        {
                            data.history.length
                                ? <ExpandableList list={data.history} />
                                : <NoInfo />
                        }
                    </div>
                </div>
            </div>
        )
    }, []);

    const closeProcedureModal = () => {
        procedureUIStore.toggleProcedureModalOpen(false);
        procedureUIStore.cleanUpProcedureModal();
    };

    const onConfirmationDecline = () => {
        procedureUIStore.toggleDeleteConfirmationModalOpened(false);
        procedureUIStore.toggleProcedureModalOpen(false);
        procedureUIStore.cleanUpProcedureModal();
    };

    const onDeleteConfirm = async () => {
        const result = await procedureUIStore.deleteProcedure();

        if (result.success) {
            toast.success(
                <ToastMessage
                    message={messages['proceduresPage.delete.success.toast']}
                    type='success'
                />
            );

            proceduresGridUIStore.setParams({ page: 0 });
            proceduresGridUIStore.load();
            proceduresGridUIStore.loadStatistics();
        }

        procedureUIStore.toggleDeleteConfirmationModalOpened(false);
        procedureUIStore.cleanUpProcedureModal();
    }

    return (
        <>
            <ProceduresSummary />

            <div className={styles.tableActions}>
                <div className={styles.invite}>
                    <Button variant="contained"
                            color="primary"
                            startIcon={<NoteAddIcon />}
                            disabled={!userPermissionsUIStore.canCreateProcedure}
                            onClick={() => procedureUIStore.toggleProcedureModalOpen(true)}>
                        {messages['proceduresPage.addProcedure.title']}
                    </Button>
                </div>

                <div className={styles.filters}>
                    <Observer>
                        {() => (
                            <>
                                <SearchForm
                                    initialValues={{
                                        search: proceduresGridUIStore.requestParams.getStorageParams
                                            ? proceduresGridUIStore.requestParams.getStorageParams.searchText
                                            : '',
                                    }}
                                    onSearchTextChanged={onSearchTextChanged}
                                    className={styles.searchWrapper}
                                    disabled={proceduresGridUIStore.status?.isLoading}
                                />
                            </>
                        )}
                    </Observer>

                    <span style={{ display: 'inherit' }} title={messages['proceduresPage.filter.title']}>
                        <FilterListOutlinedIcon
                            onClick={onFiltersModalOpen}
                            className={styles.filterIcon}
                        />
                    </span>
                </div>
                <div className={classNames(styles.exportButton)}>
                    <ProceduresExport />
                </div>
                <ProceduresFiltersModal isOpen={filtersModalOpen} onRequestClose={onFiltersModalClose} />
            </div>

            <ProceduresFiltersBar />
            <Observer>
                {() => {
                    const data = proceduresGridUIStore.procedures.toJSON();
                    const preparedData = data.map(row => ({
                        ...row,
                        subRows: [{
                            description: row.description,
                            devices: row.devices,
                            access: [...row.groups, ...row.users],
                            history: row.history ? row.history.map(history => ({
                                name: `${moment(history.date).format(DATE_MMM_DD_YYYY_HH_MM)}${history.description ? " - " + history.description : ""}`,
                            })) : null,
                        }],
                    }));

                    return (
                        <>
                            {
                                procedureUIStore.procedureModalOpened && (
                                    <ProcedureModal
                                        isOpen={true}
                                        onRequestClose={() => closeProcedureModal()}
                                    />
                                )
                            }

                            {
                                procedureUIStore.deleteConfirmationModalOpened && (
                                    <ConfirmationModal
                                        isOpen={true}
                                        message={messages['proceduresPage.delete.message']}
                                        confirmText={messages['proceduresPage.delete.confirmText']}
                                        onDecline={onConfirmationDecline}
                                        onConfirm={() =>
                                            procedureUIStore.procedureToEditId && onDeleteConfirm()
                                        }
                                    />
                                )
                            }

                            {
                                proceduresGridUIStore.isTableVisible && (
                                    <Table
                                        data={preparedData}
                                        columns={columns}
                                        fetchData={onFetchData}
                                        isLoading={proceduresGridUIStore.status.isLoading}
                                        renderRowSubComponent={renderRowSubComponent}
                                        paginationStatus={{ ...proceduresGridUIStore.pagination }}
                                        initialState={initialSortState}
                                    />
                                )
                            }

                            {
                                proceduresGridUIStore.noFilteringResults && <NoResultsView entityName='procedures' />
                            }

                            {
                                proceduresGridUIStore.noItemsInGroup && <NoProceduresInGroup />
                            }
                        </>
                    );
                }}
            </Observer>
        </>
    )
}
