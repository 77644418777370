import { IApiStore } from '@core/api/apiStore';
import { EndpointCallParams } from '@core/api/endpointCallParams';
import { ProgressEvent } from 'superagent';

export const performDownloadDownloadables = async (api: IApiStore, params: EndpointCallParams<string>, progressCallback?: (p: ProgressEvent) => void) => {
    const { payload, ...rest } = params;
    const req = api
        .getBlob('contentfileprovider-app/api/v1/FileProvider/DownloadableVersionFile', {
            ...rest,
            payload,
        },
            progressCallback);

    return req;
}
