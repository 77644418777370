import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';

import ArrowRightIcon from '@assets/arrow-right.svg';

import { useStyles } from './DownloadGroup.styles';
import { useDownloadablesUIStore } from '@core/useStores';
import { messages } from 'Download/download.messages';
import { Observer } from 'mobx-react';
import { Table } from '@shared/components/Table';
import { NoResultsView } from '@shared/components/NoResultsView';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import DownloadIcon from '@assets/download.png';
import { DATE_MMM_DD_YYYY } from '@shared/constants';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ToastMessage } from '@shared/components/Toast';
import CheckOutlinedIcon from '@mui/icons-material';
import { ProgressEvent } from 'superagent';
import { ThemeProps } from '@styles/theme';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

type SubComponentProps = {
    row: {
        original: {
            subRows: Array<{
                description: string,
                releaseNotes: string | undefined,
                olderDownloadableVersionFile: DownloadableVersionFileProps[]
            }>,
        }
    }
}
type SubComponentInternalProps = {
    row: {
        original: {
            subRows: Array<{
                releaseNotes: string | undefined
            }>,
        }
    }
}

const EXPANDER_CELL_WIDTH = 24;
const MIN_DESCRIPTION_LENGTH = 100;

const NoInfo = () => {
    const styles = useGridStyles();

    return <div className={styles.noInfo}>{messages['downloadPage.noInfo']}</div>
}

const columnHelper = createColumnHelper<DownloadableProps>();

const columnsData = [
    columnHelper.display({
        id: 'expander',
        minSize: 40,
        maxSize: 40,
        header: () => (
            <div style={{ minWidth: EXPANDER_CELL_WIDTH }} />
        ),
        cell: ({ row }) => {
            const styles = useGridStyles();
            const expanderHandler = row.getToggleExpandedHandler();

            return (
                <div
                    style={{
                        minWidth: EXPANDER_CELL_WIDTH,
                        display: 'flex',
                    }}
                    onClick={expanderHandler}
                >
                    {row.getCanExpand() && (
                        <img
                            src={ArrowRightIcon}
                            className={classNames(styles.expandIcon, { [styles.expanded]: row.getIsExpanded() })}
                        />
                    )}
                </div>
            )
        },
        size: EXPANDER_CELL_WIDTH + 16,
        enableSorting: false,
    }),
    columnHelper.accessor('name', {
        header: messages['downloadPage.table.header.name'],
        size: 18,
        maxSize: 200,
        cell: ({ getValue }) => {
            const styles = useGridStyles();

            return (
                <div className={styles.nameWrapper}>
                    <span className={styles.downloadName}>{getValue()}</span>
                </div>
            );
        },
    }),
    columnHelper.accessor('description', {
        header: messages['downloadPage.table.header.description'],
        size: 20,
        minSize: 80,
        maxSize: 700,
        cell: ({ getValue }) => {
            const styles = useGridStyles();

            return (
                <div className={styles.nameWrapper}>
                    <span className={styles.downloadName}>{getValue()}</span>
                </div>
            );
        },
    }),

    columnHelper.accessor('platformLast', {
        header: messages['downloadPage.table.header.platform'],
        size: 18,
        maxSize: 140,
        cell: ({ getValue }) => getValue() || <NoInfo />,
    }),
    columnHelper.accessor('dateLast', {
        header: messages['downloadPage.table.header.date'],
        size: 18,
        maxSize: 120,
        cell: ({ getValue }) => {

            const value = getValue();

            return (
            value ? moment(value.replace("T23:59:59Z", "T12:00:00Z")).format(DATE_MMM_DD_YYYY) : <></>);}
    }),
    columnHelper.accessor('versionLast', {
        header: messages['downloadPage.table.header.version'],
        size: 12,
        maxSize: 75,
        cell: ({ getValue }) => getValue() || <NoInfo />,
    }),
    columnHelper.display({
        id: 'download',
        minSize: 40,
        maxSize: 40,
        enableSorting: false,
        cell: ({ row }) => {
            const styles = useGridStyles();

            const downloadablesUIStore = useDownloadablesUIStore();
            const original: Record<string, any> = row.original;

            const onCellClick = () => {

                if (original.fileNameLast && original.fileNameLast != 'empty') {
                    const toastId = toast.success(
                        <ToastMessage
                            message={'Downloading ' + original.fileNameLast + '...'}
                            type='success'
                        />,
                        { autoClose: 180000 }
                    );
                    downloadablesUIStore.downloadDownloadable(original.idLast, original.fileNameLast, toastId,
                        (e: ProgressEvent) => {
                            toast.update(toastId, {
                                render: () =>
                                    <ToastMessage
                                        message={Math.round(e.percent ?? 0) + '% - Downloading ' + original.fileNameLast}
                                        type='success'
                                    />,
                            })
                        }
                    );
                } else {
                    toast.error(
                        <ToastMessage
                            message={'Missing file'}
                            type='error'
                        />,
                        { autoClose: 1000 }
                    );
                }
            }

            return (
                <button
                    onClick={() => onCellClick()}
                    className={styles.button}
                    title={messages['downloadPage.download']}
                >
                    <img src={DownloadIcon} style={{ width: 24, height: 24 }} />
                </button>
            )
        },
    }),
]

const columnInternalHelper = createColumnHelper<DownloadableVersionFileProps>();

const columnsInternalData = [
    columnInternalHelper.display({
        id: 'expander',
        minSize: 40,
        maxSize: 40,
        header: () => (
            <div style={{ minWidth: EXPANDER_CELL_WIDTH }} />
        ),
        cell: ({ cell: {row} }) => {
            const styles = useGridStyles();
            const expanderHandler = row.getToggleExpandedHandler();

            return (
                <div
                    style={{
                        minWidth: EXPANDER_CELL_WIDTH,
                        display: 'flex',
                    }}
                    onClick={expanderHandler}
                >
                    {row.getCanExpand() && (
                        <img
                            src={ArrowRightIcon}
                            className={classNames(styles.expandIcon, { [styles.expanded]: row.getIsExpanded() })}
                        />
                    )}
                </div>
            )
        },
        size: EXPANDER_CELL_WIDTH + 16,
        enableSorting: false,
    }),
    columnInternalHelper.accessor('version', {
        header: messages['downloadPage.table.header.version'],
        size: 22,
        maxSize: 640,
        cell: ({ getValue }) => getValue() || <NoInfo />,
    }),
    columnInternalHelper.accessor('platform', {
        header: messages['downloadPage.table.header.platform'],
        size: 12,
        maxSize: 140,
        cell: ({ getValue }) => getValue() || <NoInfo />,
    }),
    columnInternalHelper.accessor('date', {
        header: messages['downloadPage.table.header.date'],
        size: 18,
        maxSize: 170,
        cell: ({ getValue }) => {
            const value = getValue();
            return (value ? moment(value.replace("T23:59:59Z", "T12:00:00Z")).format(DATE_MMM_DD_YYYY) : <></>);
        },
    }),
    // {
        // header: messages['downloadPage.table.header.version'],
        // accessor: 'version',
        // size: 10,
        // maxSize: 80,
        // cell: ({ getValue }) => value || <NoInfo />,
    // },
    columnInternalHelper.display({
        id: 'download',
        minSize: 40,
        maxSize: 40,
        enableSorting: false,
        cell: ({ row }) => {
            const styles = useGridStyles();

            const downloadablesUIStore = useDownloadablesUIStore();
            const original: Record<string, any> = row.original;

            const onCellClick = () => {

                if (original.fileName && original.fileName != 'empty') {
                    const toastId = toast.success(
                        <ToastMessage
                            message={'Downloading ' + original.file + '...'}
                            type='success'
                        />,
                        { autoClose: 180000 }
                    );
                    downloadablesUIStore.downloadDownloadable(original.id, original.fileName, toastId,
                        (e: ProgressEvent) => {
                            toast.update(toastId, {
                                render: () =>
                                    <ToastMessage
                                        message={Math.round(e.percent ?? 0) + '% - Downloading ' + original.fileNameLast}
                                        type='success'
                                    />,
                            })
                        }
                    );
                } else {
                    toast.error(
                        <ToastMessage
                            message={'Missing file'}
                            type='error'
                        />,
                        { autoClose: 1000 }
                    );
                }
            }

            return (
                <button
                    onClick={() => onCellClick()}
                    className={styles.button}
                    title={messages['downloadPage.download']}
                >
                    <img src={DownloadIcon} style={{ width: 24, height: 24 }} />
                </button>
            )
        },
    }),
]

export type DownloadableVersionFileProps = {
    id: string,
    version: string,
    releaseNotes: string | null,
    platform: string,
    fileName: string,
    date: string | null
}

export type DownloadableProps = {
    name: string,
    description: string,
    olderDownloadableVersionFile: DownloadableVersionFileProps[],
    idLast?: string,
    versionLast?: string,
    releaseNotesLast?: string | null,
    platformLast?: string,
    fileNameLast?: string,
    dateLast: string | null
}

export type DownloadGroupProps = {
    name: string,
    description: string,
    downloadables: DownloadableProps[]
}

const useGridStyles = () => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });
    return styles;
}

export const DownloadGroup: React.FC<DownloadGroupProps> = ({ name, description, downloadables }) => {
    const downloadablesUIStore = useDownloadablesUIStore();
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });
    const renderRowSubComponentInternal = React.useCallback(({ row }: SubComponentInternalProps) => {
        const dataInternal = row.original.subRows[0];

        return (
            <div className={styles.bodySubComponent}>
                <div className={styles.descriptionWrapper}>
                    <h5 className={styles.header}>{messages['downloadPage.subRow.releaseNotes']}</h5>
                    <div className={styles.text}>
                        {
                            dataInternal.releaseNotes
                                ? <ReactMarkdown className={styles.markdown}>{dataInternal.releaseNotes}</ReactMarkdown>
                                : <NoInfo />
                        }
                    </div>
                </div>
            </div>
        )
    }, []);
    const columnsInternal: ColumnDef<DownloadableVersionFileProps, any>[] = React.useMemo(() => columnsInternalData, []);
    const renderRowSubComponent = React.useCallback(({ row }: SubComponentProps) => {
        const data = row.original.subRows[0];
        const preparedDataInternal = data.olderDownloadableVersionFile && data.olderDownloadableVersionFile.length > 0 && data.olderDownloadableVersionFile.map(row => ({
            ...row,
            subRows: [{
                releaseNotes: row.releaseNotes,
            }],
        }));


        return (
            <div className={styles.bodySubComponent}>
                {   data.description && data.description.length > MIN_DESCRIPTION_LENGTH &&
                    <div className={styles.descriptionWrapper}>
                        <h5 className={styles.header}>{messages['downloadPage.subRow.description']}</h5>
                        <div className={styles.text}>
                            {data.description || <NoInfo />}
                        </div>
                    </div>
                }
                {
                    data.releaseNotes && (
                        <div className={styles.descriptionWrapper}>
                            <h5 className={styles.header}>{messages['downloadPage.subRow.releaseNotes']}</h5>
                            <div className={styles.text}>
                                <ReactMarkdown className={styles.markdown}>{data.releaseNotes}</ReactMarkdown>
                            </div>
                        </div>
                    )
                }
                {
                    preparedDataInternal && (
                        <div className={styles.descriptionWrapper}>
                            <h5 className={styles.header}>{messages['downloadPage.subRow.olderVersions']}</h5>
                            <Table
                                className={styles.subTable}
                                data={preparedDataInternal}
                                columns={columnsInternal}
                                isLoading={downloadablesUIStore.isLoading}
                                renderRowSubComponent={renderRowSubComponentInternal}
                                paginationDisabled={true}
                                paginationStatus={{ ...downloadablesUIStore.pagination }}
                                initialState={initialSortState}
                                fetchData={() => { }}
                            />
                        </div>
                    )
                }

            </div>
        )
    }, []);
    const initialSortState = {
        sorting: [],
    };
    const columns: ColumnDef<DownloadableProps, any>[] = React.useMemo(() => columnsData, []);

    return (
        <>
            <Observer>
                {() => {
                    const data = downloadables;
                    const preparedData = data.map(row => ({
                        ...row,
                        subRows: [{
                            description: row.description,
                            releaseNotes: row.releaseNotesLast,
                            olderDownloadableVersionFile: row.olderDownloadableVersionFile,
                        }],
                    }));

                    return (
                        <>
                            <div className={styles.groupWrapper}>
                                <div className={styles.h4}>{name}</div>
                                <div className={styles.text}>{description}</div>
                            </div>
                            {
                                downloadables && downloadables.length > 0 && (
                                    <Table
                                        className={styles.tableFlexible}
                                        data={preparedData}
                                        columns={columns}
                                        isLoading={downloadablesUIStore.isLoading}
                                        renderRowSubComponent={renderRowSubComponent}
                                        paginationDisabled={true}
                                        paginationStatus={{ ...downloadablesUIStore.pagination }}
                                        initialState={initialSortState}
                                        fetchData={() => { }}
                                    />
                                )
                            }

                            {
                                (!downloadables || downloadables.length == 0) && <NoResultsView entityName='downloads' />
                            }
                        </>
                    );
                }}
            </Observer>
        </>
    )
}
